export const feelsInitialState = {
	initialized: false,
	loading: false,
	items: [],
	feelsStatus: null,
};

export const feelsReducer = (state = feelsInitialState, action) => {
	switch (action.type) {
		case "FEELS_LOADING":
			return {
				...state,
				loading: true,
			};

		case "FEELS_GET":
			return {
				...state,
				loading: false,
				items: action.payload.data.data,
			};

		case "FEELS_ERROR":
			return {
				...state,
				loading: false,
			};

		case "FEELS_STATUS_GET":
			return {
				...state,
				loading: false,
				feelsStatus: action.payload.data.data,
				
			};

		default:
			return state;
	}
};
