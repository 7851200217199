import React, { useState, useEffect } from "react";

import {
	EmojiAnswer1,
	EmojiAnswer2,
	EmojiAnswer3,
	EmojiAnswer4,
	EmojiAnswer5,
} from "./common/NPSicons.js";
import FeedbackForm from '../Views/FeedbackForm.js';
import { config } from '../config.js';

const MODE_EMOJI = "emoji";
// const MODE_CLASSIC = "classic";


// const config = window?._DQC_NPS_CONF;

// const project_id = config?.id ?? 1;
// const feedbackMode = config?.mode ?? MODE_EMOJI;
// const minimumVisits = config?.minimumVisits ?? 4;
// const popupDelay = config?.popupDelay ?? 10000;

const translations_original = {
	header:
		" Kiitos vierailustasi sivustollamme! Olisimme kiitollisia, jos voisit vastata nopeasti yhteen kysymykseen koskien sivustoamme!",
	success_title: "Kiitos vastauksesta!",
	close: "Sulje",
	question: "Sivustoa on helppo käyttää:",
	answer_1: "Täysin eri mieltä",
	answer_2: "Eri mieltä",
	answer_3: "Ei samaa eikä eri mieltä",
	answer_4: "Samaa mieltä",
	answer_5: "Täysin samaa mieltä",
	comment_title: "Open feedback",
	send_cta: "Send",
};

const translations_emoji = {
	header: "How are you feelin' today?",
};

const debugMode =
	document.location.host === "nps.atk7.dqcomms.com" ||
	document.location.hostname === "localhost" ||
	document.location.hostname === "127.0.0.1";

if (debugMode) {
	console.log("debugMode", debugMode, document.location.host);
}

// const createCookie = (name, value, days) => {
// 	let expires = "";
// 	if (days) {
// 		const date = new Date();
// 		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
// 		expires = "; expires=" + date.toUTCString();
// 	} else {
// 		//expire on next sunday nigth or monday morning
// 		const date = new Date();
// 		date.setDate(date.getDate() + ((7 - date.getDay()) % 7) + 1);
// 		date.setHours(0, 0, 0, 0);
// 		expires = "; expires=" + date.toUTCString();
// 	}

// 	var cookieval = name + "=" + value + expires + "; path=/ ; SameSite=Strict";
// 	window.document.cookie = cookieval;
// };

// const readCookie = (name) => {
// 	const nameEQ = name + "=";
// 	const ca = window.document.cookie.split(";");

// 	if (debugMode) {
// 		console.log("debugMode", debugMode, document.location.host);
// 	}
// 	// if (debugMode) return minimumVisits;

// 	for (let i = 0; i < ca.length; i++) {
// 		let c = ca[i];
// 		while (c.charAt(0) === " ") c = c.substring(1, c.length);
// 		if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
// 	}

// 	return false;
// };

const defaulConfig = {
	project_id: 'feels',
	mode: MODE_EMOJI,
	minimumVisits: 0,
	popupDelay: 1000,
};

export const NPSTrigger = ({ children }) => {
	const handleClick = () => {
		const event = new Event("openNPSDialog");
		window.dispatchEvent(event);
	};

	return React.cloneElement(children, {
		onClick: handleClick,
	});
};

export const NPSModal = ({
	user,
	response,
	config = defaulConfig,
	useOverlay = false,
	isEnabledFn = null,
	onDismiss = () => { },
}) => {
	let finalConfig = { ...defaulConfig, ...config };
	const { project_id, mode, minimumVisits, popupDelay } = finalConfig;

	const [isVisible, setIsVisible] = useState(false);
	// const cookieName = `dqc-nps-${project_id}`;
	// const cookieLifetimeInDay = 5;

	useEffect(() => {
		// var cookieContent = readCookie(cookieName);

		// if (debugMode) {
		// 	console.log("cookieContent", cookieContent);
		// }

		// if (cookieContent) {
		// 	// if cookie exists
		// 	if (parseInt(cookieContent) > 0) {
		// 		// integer < X, don't show
		// 		cookieContent = parseInt(cookieContent) + 1;
		// 		createCookie(cookieName, cookieContent);
		// 	}
		// }

		// if (!cookieContent) {
		// 	// if no cookie, create one with display_count = 1
		// 	cookieContent = 1;
		// 	createCookie(cookieName, cookieContent);
		// }

		// if (cookieContent && parseInt(cookieContent) >= minimumVisits) {
		let isPrevented =
			typeof isEnabledFn == "function" ? !isEnabledFn() : false;

		if (isPrevented) {
			console.log("dqcnps blocked via callback");
		} else {
			setTimeout(() => {
				setIsVisible(true);
			}, popupDelay);
		}
		// }

		const handleCustomEvent = () => {
			setIsVisible(true);
		};

		window.addEventListener("openNPSDialog", handleCustomEvent);

		return () => {
			window.removeEventListener("openNPSDialog", handleCustomEvent);
		};
	}, [isEnabledFn, popupDelay]);

	const closeModalFn = () => {
		setIsVisible(false);
		const event = new Event("NPSDialog_closed");
		window.dispatchEvent(event);
	};

	const closeWithoutSending = () => {
		closeModalFn();
		// createCookie(cookieName, "closed", cookieLifetimeInDay);
		//TODO: trigger FEELS_IGNORED
		onDismiss();
	};

	return (
		<>
			<div
				id={"npsmodal"}
				className={`${isVisible ? "isvisible" : "isnotvisible"}`}
			>
				<button id={"npsmodal__close"} onClick={closeWithoutSending}>
					&nbsp;
				</button>
				<NPS
					project_id={project_id}
					feedbackMode={mode}
					closeModal={closeModalFn}
					onDismiss={onDismiss}
					user={user}
					response={response}
				/>
			</div>
			{useOverlay && (
				<div
					id={"npsmodal__overlay"}
					className={`${isVisible ? "isvisible" : "isnotvisible"}`}
					onClick={closeWithoutSending}
				></div>
			)}
		</>
	);
};

const Loader = () => {
	return (
		<div className={`loader`}>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	);
};

// function ClassicFeedback({
// 	handleOnChange,
// 	handleSubmit,
// 	closeModal,
// 	submitted,
// 	loading,
// 	isValid,
// 	state,
// 	translations,
// }) {
// 	return (
// 		<>
// 			<div id={'npsmodal__header'}>{translations.header}</div>

// 			{submitted && (
// 				<div id={`npsmodal__score`}>
// 					<p>{translations.success_title}</p>

// 					<button id={`npsmodal__darkbutton`} onClick={closeModal}>
// 						{translations.close}
// 					</button>
// 				</div>
// 			)}
// 			{!submitted && (
// 				<form
// 					onSubmit={handleSubmit}
// 					className={`${loading ? "is-loading" : ""}`}
// 				>
// 					<div id={`npsmodal__score`}>
// 						<p> {translations.question}</p>
// 						<label>
// 							<input
// 								type="radio"
// 								name="score"
// 								value="1"
// 								onChange={handleOnChange}
// 							/>{" "}
// 							1 = {translations.answer_1}
// 						</label>
// 						<label>
// 							<input
// 								type="radio"
// 								name="score"
// 								value="2"
// 								onChange={handleOnChange}
// 							/>{" "}
// 							2 = {translations.answer_2}
// 						</label>
// 						<label>
// 							<input
// 								type="radio"
// 								name="score"
// 								value="3"
// 								onChange={handleOnChange}
// 							/>{" "}
// 							3 = {translations.answer_3}
// 						</label>
// 						<label>
// 							<input
// 								type="radio"
// 								name="score"
// 								value="4"
// 								onChange={handleOnChange}
// 							/>{" "}
// 							4 = {translations.answer_4}
// 						</label>
// 						<label>
// 							<input
// 								type="radio"
// 								name="score"
// 								value="5"
// 								onChange={handleOnChange}
// 							/>{" "}
// 							5 = {translations.answer_5}
// 						</label>
// 					</div>

// 					<div id={'npsmodal__comment'}>
// 						<label>{translations.comment_title}</label>
// 						<textarea
// 							name="comment"
// 							onChange={handleOnChange}
// 							value={state.comment}
// 						></textarea>
// 					</div>

// 					{loading && <Loader />}
// 					{!loading && (
// 						<button
// 							type="submit"
// 							disabled={!isValid}
// 							id={'npsmodal__button'}
// 						>
// 							{translations.send_cta}
// 						</button>
// 					)}
// 				</form>
// 			)}
// 		</>
// 	);
// }

function EmojiFeedback({
	handleOnChange,
	handleSubmit,
	closeModal,
	submitted,
	loading,
	isValid,
	state,
	translations,
}) {

	const compareScore = (state?.score ?? 0).toString();

	return (
		<>
			<div id={"npsmodal__header"}>{translations.header}</div>

			{submitted && (
				<div id={`npsmodal__score`}>
					<p>{translations.success_title}</p>

					<button id={`npsmodal__darkbutton`} onClick={closeModal}>
						{translations.close}
					</button>
				</div>
			)}

			{!submitted && (
				<>
					<form
						onSubmit={handleSubmit}
						className={`${loading ? "is-loading" : ""}`}
					>
						<div id={`npsmodal__score`}>
							<div className={`emoji__list`}>
								<label>
									<input
										type="radio"
										name="score"
										value="1"
										onChange={handleOnChange}
										checked={compareScore === "1"}
									/>
									<EmojiAnswer1 />
								</label>
								<label>
									<input
										type="radio"
										name="score"
										value="2"

										onChange={handleOnChange}
										checked={compareScore === "2"}
									/>
									<EmojiAnswer2 />
								</label>
								<label>
									<input
										type="radio"
										name="score"
										value="3"
										onChange={handleOnChange}
										checked={compareScore === "3"}
									/>
									<EmojiAnswer3 />
								</label>
								<label>
									<input
										type="radio"
										name="score"
										value="4"
										onChange={handleOnChange}
										checked={compareScore === "4"}
									/>
									<EmojiAnswer4 />
								</label>
								<label>
									<input
										type="radio"
										name="score"
										value="5"
										onChange={handleOnChange}
										checked={compareScore === "5"}
									/>
									<EmojiAnswer5 />
								</label>
							</div>
						</div>

						{/* {!loading && !state.score && (
						<button
							type="submit"
							disabled={!isValid}
							id={"npsmodal__button"}
							className="c-button"
						>
							{translations.send_cta}
						</button>
					)} */}
					</form>
					{state.score > 0 && (
						<div id={"npsmodal__comment"}>
							{/* <label>{translations.comment_title}</label>
							<textarea
								name="comment"
								onChange={handleOnChange}
								value={state.comment}
							></textarea> */}
							<FeedbackForm variant="mini" mood={state.score} />
							<button className="c-button small ghost" onClick={closeModal}>
								Close
							</button>
						</div>
					)}

					{loading && <Loader />}
				</>
			)}
		</>
	);
}

const availableEmojis = {
	score_1: ["😞", "😢", "😕"],
	score_2: ["😞", "🤔", "😐", "😕"],
	score_3: ["✅", "🙂", "😐", "😑", "😶"],
	score_4: ["✅", "👍", "🙂", "😊"],
	score_5: ["👍", "🔥", "😎", "🤩", "😍", "🎉", "👏"],
};

function NPS({ onDismiss, response, user, closeModal, project_id, feedbackMode }) {
	const [loading, setLoading] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	// const [responseId, setResponseId] = useState(false);


	const [state, setState] = useState({
		// project_id: project_id,
		// comment: "",
		score: response?.score ?? null,
	});
	const isValid = state?.score ?? false;


	useEffect(() => {
		if (response?.score != state.score) {
			handleSubmit(false, true);
		} else {
			// console.log("no change, already submitted this");
		}
	}, [state.score]);

	useEffect(() => {
		setState({ ...state, score: response?.score ?? null });
	}, [response]);

	const [emojis, setEmojis] = useState([]);

	const spawnEmoji = () => {
		// Add a new emoji with a unique key
		const id = Date.now();
		const randomLeft = Math.max(15, Math.min(85, Math.floor(Math.random() * 100))) - 15;
		const randomEmoji = availableEmojis[`score_${state.score}`][Math.floor(Math.random() * availableEmojis[`score_${state.score}`].length)];
		setEmojis([...emojis, { id, left:randomLeft, e: randomEmoji }]);

		// Remove the emoji after animation (3 seconds)
		setTimeout(() => {
			setEmojis((prevEmojis) => prevEmojis.filter((emoji) => emoji.id !== id));
		}, 3000);
	};


	const handleOnChange = (event) => {
		setState({ ...state, [event.target.name]: event.target.value });
	};

	var translations = translations_original;
	if (feedbackMode === MODE_EMOJI) {
		translations = { ...translations, ...translations_emoji };
	}

	const handleSubmit = (event, background) => {
		if (!state || !state.score) {
			return false;
		}

		if (event) event.preventDefault();
		setLoading(true);

		const locationInfo = {
			path: document.location.pathname,
			host: document.location.hostname,
		};

		// let requestData = { ...state, ...locationInfo };
		let requestData = { data: { ...state, ...locationInfo } };
		// if (responseId) {
		// 	requestData.hash = responseId;
		// }
		const token = user?.token ?? null;

		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json", "Authorization": token },
			body: JSON.stringify(requestData),
		};


		// fetch("https://nps.atk7.dqcomms.com/api/response-or-update", requestOptions)
		fetch(config.apiUrl + "/feels/response-or-update", requestOptions)
			.then((response) => response.json())
			.then((data) => {
				setLoading(false);
				spawnEmoji();
				if (!background) {
					setSubmitted(true);
				}
				// setResponseId(data.hash);
				// createCookie(`dqc-nps-${project_id}`, "sent");
				//TODO: trigger FEELS_IGNORED
				onDismiss();
			})
			.catch((error) => {
				setLoading(false);
			});
	};

	return (
		<div id={"npsmodal__nps"} className={"emoji"}>
			{feedbackMode === MODE_EMOJI && (
				<EmojiFeedback
					handleOnChange={handleOnChange}
					handleSubmit={handleSubmit}
					closeModal={closeModal}
					loading={loading}
					submitted={submitted}
					isValid={isValid}
					state={state}
					translations={translations}
				/>
			)}
			{/* {feedbackMode === MODE_CLASSIC && (
				<ClassicFeedback
					handleOnChange={handleOnChange}
					handleSubmit={handleSubmit}
					closeModal={closeModal}
					loading={loading}
					submitted={submitted}
					isValid={isValid}
					state={state}
					translations={translations}
				/>
			)} */}
			<div className="emoji-container">
				{emojis.map((emoji) => (
					<div
						className="emoji-wrapper"
						key={emoji.id}
						style={{ left: `${emoji.left}%` }}
					>
						<span className="emoji">{emoji.e}</span>
					</div>
				))}
			</div>
		</div>
	);
}

export default NPS;