import React from "react";

export const EmojiAnswer1 = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height="48px"
			viewBox="0 0 24 24"
			width="48px"
			fill="currentColor"
		>
			<path d="M0 0h24v24H0V0z" fill="none" />
			<circle cx="15.5" cy="9.5" r="1.5" />
			<circle cx="8.5" cy="9.5" r="1.5" />
			<path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm0-6c-2.33 0-4.32 1.45-5.12 3.5h1.67c.69-1.19 1.97-2 3.45-2s2.75.81 3.45 2h1.67c-.8-2.05-2.79-3.5-5.12-3.5z" />
		</svg>
	);
};
export const EmojiAnswer2 = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height="48px"
			viewBox="0 0 24 24"
			width="48px"
			fill="currentColor"
		>
			<path d="M0 0h24v24H0V0z" fill="none" />
			<circle cx="15.5" cy="9.5" r="1.5" />
			<circle cx="8.5" cy="9.5" r="1.5" />
			<path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm0-3.5c.73 0 1.39.19 1.97.53.12-.14.86-.98 1.01-1.14-.85-.56-1.87-.89-2.98-.89-1.11 0-2.13.33-2.99.88.97 1.09.01.02 1.01 1.14.59-.33 1.25-.52 1.98-.52z" />
		</svg>
	);
};
export const EmojiAnswer3 = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height="48px"
			viewBox="0 0 24 24"
			width="48px"
			fill="currentColor"
		>
			<path d="M0 0h24v24H0V0z" fill="none" />
			<path d="M9 15.5h6v1H9v-1z" />
			<circle cx="15.5" cy="9.5" r="1.5" />
			<circle cx="8.5" cy="9.5" r="1.5" />
			<path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
		</svg>
	);
};
export const EmojiAnswer4 = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height="48px"
			viewBox="0 0 24 24"
			width="48px"
			fill="currentColor"
		>
			<path d="M0 0h24v24H0V0z" fill="none" />
			<circle cx="15.5" cy="9.5" r="1.5" />
			<circle cx="8.5" cy="9.5" r="1.5" />
			<path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm0-4c-.73 0-1.38-.18-1.96-.52-.12.14-.86.98-1.01 1.15.86.55 1.87.87 2.97.87 1.11 0 2.12-.33 2.98-.88-.97-1.09-.01-.02-1.01-1.15-.59.35-1.24.53-1.97.53z" />
		</svg>
	);
};
export const EmojiAnswer5 = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height="48px"
			viewBox="0 0 24 24"
			width="48px"
			fill="currentColor"
		>
			<path d="M0 0h24v24H0V0z" fill="none" />
			<circle cx="15.5" cy="9.5" r="1.5" />
			<circle cx="8.5" cy="9.5" r="1.5" />
			<path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm-5-6c.78 2.34 2.72 4 5 4s4.22-1.66 5-4H7z" />
		</svg>
	);
};
