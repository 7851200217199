/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
// import { apiClient } from '../Helpers/apiCall';
import { useStore } from '../../store/useStore';
import Loader from '../Components/common/Loader';
import { Link, NavLink } from 'react-router-dom';
import MainTitle from '../Components/common/MainTitle';
import { Redirect } from 'react-router-dom';
import Timestamp from '../Components/common/Timestamp';
import { ICON_DROPDOWN_ARROW_IMG } from '../Helpers/icons';

const baseUrl = '/weekly';

export default function Weekly({ pageId }) {
	const { state } = useStore();
	// const apiCall = apiClient(state, dispatch);

	pageId = encodeURIComponent(pageId).toLocaleLowerCase();

	const { weekly: pages } = state;

	const manualPages = [...(pages?.items ?? [])];

	// const manualPages = [...(pages?.items ?? [])].map((page) => {
	// 	const year = new Date(page.date).getFullYear();
	// 	page.parent = year + "0000";
	// 	return page;
	// });
		
	// console.log(manualPages);
	// const weeklyPages = [...(pages?.items ?? [])].reduce((acc, page) => {
	// 	const year = new Date(page.date).getFullYear();
	// 	if (!acc[year]) acc[year] = [];
	// 	acc[year].push(page);
	// 	return acc;
	// }, {});

	// const fetchX = (key) => {
	// 	if (!state[key].loading) {
	// 		dispatch({ type: `${key.toUpperCase()}_LOADING` });
	// 		apiCall.GET(`${key}`);
	// 	}
	// };
	

	useEffect(() => {
		// eslint-disable-next-line no-unused-vars
		let isMounted = true;

		// fetchX('weekly');

		return () => {
			// eslint-disable-next-line no-unused-vars
			isMounted = false;
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const page = manualPages.find((i) => i.slug == pageId);

	if ((!pageId || pageId == 'undefined') && !pages.loading) {
		let tmpPage = manualPages[0];
		if (tmpPage && tmpPage.slug) return <Redirect to={`${baseUrl}/${tmpPage.slug}`} />;		
	}

	return (
		<div className="c-manual weekly">
			<MainTitle>Queendom's weekly</MainTitle>

			<div className="">
				{pages.loading && <Loader className=""></Loader>}
				{!pages.loading && (
					<section className="wp-nav">
						{/* <BackButton /> */}

						<div className="">
							<SubPages
								pageId={0}
								isLoading={pages.loading}
								pages={manualPages}
								currentPage={page}
							/>
						</div>
					</section>
				)}
				{!pages.loading && pageId && (
					<PageTemplate page={page} pages={manualPages} />
				)}
			</div>
		</div>
	);
}

const SubPages = ({ isLoading, pages, pageId, level, currentPage = false }) => {

	if (!pageId) pageId = 0;
	if (!level) level = 0;

	const filteredPages = pages && pages.filter((i) => (i.parent == pageId || (pageId == 0 && !i.parent) ) );
	// const filteredPages = pages && pages.filter((i) => (i.parent == pageId ) );

	const yearChunks = filteredPages.reduce((acc, page) => {
		const year = new Date(page.date).getFullYear();
		if (!acc[year]) acc[year] = [];
		acc[year].push(page);
		return acc;
	}, {});
	
	if (isLoading) return <Loader className="dark" />;

	return Object.entries(yearChunks).reverse().map(([year, yearChunk]) => {
		
		const currentPageYear = currentPage ? new Date(currentPage.date).getFullYear() : false;
		const isCurrentYearOpen = !currentPageYear || currentPageYear == year;
		
		return yearChunk.length > 0 ? (
			<YearChunk
				year={year}
				pages={yearChunk}
				defaultOpen={isCurrentYearOpen}
				key={year}
			/>
		) : (
			// <div className='yearchunk'>
			// 	<h3>{year}</h3>
			// 	<ul className={` ${level > 0 ? "sub sub-" + level : ""}`}>
			// 		{yearChunk.map((page) => {
			// 			return (
			// 				<li key={page.id}>
			// 					<span className="issue-number">
			// 						<Timestamp
			// 							timestamp={page.date}
			// 							format={"'Week' II / u"}
			// 							pure={true}
			// 						/>
			// 					</span>
			// 					<NavLink
			// 						activeClassName="active"
			// 						to={`${baseUrl}/${page.slug}`}
			// 						dangerouslySetInnerHTML={{ __html: page?.title?.rendered }}
			// 					/>
			// 					{/* <SubPages
			// 						pageId={page.id}
			// 						isLoading={isLoading}
			// 						pages={pages}
			// 						level={level + 1}
			// 					/> */}
			// 				</li>
			// 			);
			// 		})}
			// 	</ul>
			// </div>
			<></>
		);
	});
	// return (
	// 	filteredPages.length > 0 ?
	// 	<ul className={` ${level > 0 ? 'sub sub-'+level : ''}`}>
	// 		{filteredPages.map((page) => {
	// 					return (
	// 						<li key={page.id}>
	// 							<span className='issue-number'>
	// 								<Timestamp timestamp={page.date} format={"'Week' II / u"} pure={true} />
	// 							</span>
	// 							<NavLink
	// 								activeClassName="active"
	// 								to={`${baseUrl}/${page.slug}`}
	// 								dangerouslySetInnerHTML={{ __html: page.title.rendered }}
	// 							/>
	// 							{/* <SubPages
	// 								pageId={page.id}
	// 								isLoading={isLoading}
	// 								pages={pages}
	// 								level={level + 1}
	// 							/> */}
	// 						</li>
	// 					);
	// 				})}
	// 		{isLoading && <Loader className="dark" />}
	// 		</ul>
	// 		: <></>
	// );
};

const YearChunk = ({ year, pages, defaultOpen = false }) => {

	const [open, setOpen] = useState(defaultOpen);

	const level = 1;
	return (
		<div className={`c-yearchunk ${open ? "active" : ""}`}>
			<div className="toggler" onClick={() => setOpen(!open)}>
				<h3>{year}</h3>
				<ICON_DROPDOWN_ARROW_IMG />
			</div>
			<ul className={` ${level > 0 ? "sub sub-" + level : ""}`}>
				{pages.map((page) => {
					return (
						<li key={page.id}>
							<span className="issue-number">
								<Timestamp
									timestamp={page.date}
									format={"'Week' II / u"}
									pure={true}
								/>
							</span>
							<NavLink
								activeClassName="active"
								to={`${baseUrl}/${page.slug}`}
								dangerouslySetInnerHTML={{ __html: page?.title?.rendered }}
							/>
							{/* <SubPages
									pageId={page.id}
									isLoading={isLoading}
									pages={pages}
									level={level + 1}
								/> */}
						</li>
					);
				})}
			</ul>
		</div>
	);
};

const PageTemplate = ({ page, pages }) => {

	if (!page) {
		return <Loader className=""></Loader>;
	}

	const parent = page.parent != 0 ? pages.find(i => page.parent == i.id) : false;

	return (
		<section id={page.slug} className="c-page article wp-page">
			<h1 className='article-title'>
				{parent ? (
					<>
						<Link
							to={parent.slug}
							dangerouslySetInnerHTML={{ __html: parent.title.rendered }}
						/>
						<span> / </span>
					</>
				) : (
					''
				)}
				<span
					className="title"
					dangerouslySetInnerHTML={{ __html: page.title.rendered }}
				/>
			</h1>
			{/* <BackButton onClick={() => history.push(backUrl)} /> */}
			<div className="c-article">
				<div
					dangerouslySetInnerHTML={{ __html: page?.content?.rendered ?? '' }}
				></div>
			</div>
			<div className="fixer negateTopMargin">
				<SubPages pageId={page.id} isLoading={!page} pages={pages} />
			</div>
			{/* <div className="c-article-nav">
				{prevArticle && (
					<Link className="prev" to={`/manual/${prevArticle.slug}`}>
						{prevArticle.title.rendered}
					</Link>
				)}
				{nextArticle && (
					<Link className="next" to={`/manual/${nextArticle.slug}`}>
						{nextArticle.title.rendered}
					</Link>
				)}
			</div> */}
		</section>
	);
};
