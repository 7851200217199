import { config } from '../App/config';
import { toast } from 'react-toastify';
import JWT from '../App/Helpers/JWT';

const localStorageKey = config.localStoragePrefix + 'user';

const defaultState = {
	phone: config.isDev ? config.devPhone : '',
	code: '',
	loading: false,
	loggedIn: false,
	loginhasFailed: false,
	notificationsLoading: false,
	token: null,
	data: null,
	feelsIgnored: null
};

export const userInitialState = (() => {
	let state = window.localStorage.getItem(localStorageKey)
		? {
				...defaultState,
				...JSON.parse(window.localStorage.getItem(localStorageKey)),
		  }
		: defaultState;
	state.notificationsLoading = false;
	state.loading = false;
	return state;
})();

export const userReducer = (state = userInitialState, action) => {
	let user = state;
	switch (action.type) {
		case 'LOGINFORM_INPUT':
			var ns = { ...state, [action.key]: action.value };

			user = ns;
			break;

		case 'LOGIN':
			user = {
				...state,
				loggedIn: false,
				loading: true,
				token: null,
				feelsIgnored: null,
			};
			break;
		
		case 'FEELS_IGNORED':
			user = {
				...state,
				feelsIgnored: action.payload.data.data,
			};
			// console.log('FEELS_IGNORED', action.payload, user);
			break;

		case 'AUTH/EXCHANGE-TOKEN_GET':
		case 'LOGIN_SUCCESS':
		case 'USER_REFRESH':
			let data = JWT.getUserFromToken(action.payload.headers.authentication);
			user = {
				...state,
				loggedIn: data !== undefined,
				loginhasFailed: false,
				loading: false,
				token:
					data !== undefined ? action.payload.headers.authentication : null,
				data: data !== undefined ? data : null,
			};
			break;
		case 'LOGIN_FAIL':
			toast.error(
				'Login failed, please ensure you are using a valid dqcomms.com account'
			);
			user = {
				...state,
				loginhasFailed: true,
				loggedIn: false,
				loading: false,
				token: null,
				data: null,
			};
			break;

		// case 'REQUEST_LOGIN':
		// 	user = { ...state, loading: true };
		// 	break;

		// case 'REQUEST_LOGIN_SUCCESS':
		// 	// console.debug(`SMS CODE:`, `${action.payload.data.debug_code}`);
		// 	// console.debug(`SMS LINK:`, `${action.payload.data.debug_link}`);
		// 	if (config.isDev) {
		// 		toast.info(`SMS CODE: ${action.payload.data.debug_code}`);
		// 	}
		// 	// toast.info(`SMS LINK: ${action.payload.data.debug_link}`);
		// 	user = { ...state, loading: false };
		// 	break;

		// case 'REQUEST_LOGIN_FAIL':
		// 	user = { ...state, loading: false };
		// 	break;

		case 'CHANGE_NOTIFICATION_SETTINGS':
			user = { ...state, notificationsLoading: true };
			break;
		case 'CHANGE_NOTIFICATION_SETTINGS_FAIL':
		case 'CHANGE_NOTIFICATION_SETTINGS_SUCCESS':
			user = { ...state, notificationsLoading: false };
			break;

		case 'LOGOUT':
			user = {
				...state,
				loggedIn: false,
				loading: false,
				token: null,
				data: null,
				notificationsLoading: false,
				feelsIgnored: null,
			};
			// window.localStorage.setItem(localStorageKey, JSON.stringify(user));
			//remove localstorage entirely
			window.localStorage.removeItem(localStorageKey);
			window.localStorage.removeItem(config.localStoragePrefix + "common");
			window.localStorage.removeItem("common");

			if (!action.silent) {
				window.location = '/';
			}
			return user;
			// eslint-disable-next-line no-unreachable
			break;

		default:
			user = state;
			break;
	}

	const { code, ...storeData } = user;
	window.localStorage.setItem(localStorageKey, JSON.stringify(storeData));

	return user;
};
