import React, { useEffect } from 'react';
import {
	useHistory,
	useLocation,
	Switch,
	Route
} from 'react-router-dom';

import { toast, Flip } from 'react-toastify';

import { useStore } from '../store/useStore';
import { messaging } from '../init-fcm';
import { AdminRoute } from './Routes/AdminRoute';

import 'react-toastify/dist/ReactToastify.css';
import './Styles/App.scss';
import 'tiny-slider/dist/tiny-slider.css';
import Footer from './Components/Footer';
import Splash from './Views/Splash';
import LoginRoute from './Routes/LoginRoute';
import UserRoute from './Routes/UserRoute';

function App() {
	const { state } = useStore();
	const { user } = state;

	const { pathname } = useLocation();
	var history = useHistory();


	if (messaging) {
		messaging.onMessage(function (payload) {
			// Messages received. Either because the
			// app is running in the foreground, or
			// because the notification was clicked.
			// `payload` will contain your data.

			const notification = payload.notification;
			// console.log('onMessage', payload);
			toast(`${notification.title}`, {
				position: toast.POSITION.TOP_CENTER,
				transition: Flip,
				onClick: () => {
					// console.log(window, notification);
					history.push(payload.data.click_action);
				},
			});
		});
	}

	useEffect(() => {
		window.scrollTo(0, 0);

		const currentBackgroundColor = getComputedStyle(
			document.querySelector('.app-container')
		).getPropertyValue('--background');

		document
			.querySelector('[name="theme-color"]')
			.setAttribute('content', currentBackgroundColor);
		// location.pathname.replaceAll('/', ' ');
	}, [pathname]);

	const loggedIn = user.loggedIn;
	const loading = user.loading;
	const location = useLocation();	

	const needsOnboarding = user && user.data && user.data.needs_onboarding;

	return (
		<>
			<div
				className={`app-container ${location.pathname.replaceAll('/',' ')} ${needsOnboarding ? 'onboarding' : '' }`}
			>
				{loading && <Splash />}
				<Switch>
					{!loggedIn && <LoginRoute />}
					{loggedIn && (
						<Route
							path="/admin"
							render={({ match: { url } }) => {
								return <AdminRoute url={url} />;
							}}
						/>
					)}
					{loggedIn && <UserRoute />}
					{/* <Route path="*" render={() => <Redirect to="/" />} /> */}
				</Switch>
			</div>
			<Footer />
		</>
	);
}

export default App;