/* eslint-disable eqeqeq */
import axios from "axios";
import { config } from "../config";

const axiosConf = {};

export const axiosClient = axios.create(axiosConf);

export const apiClient = (state, dispatch) => {
	const token =
		state && state.user && state.user.token ? state.user.token : false;

	axiosClient.interceptors.response.use(
		function (response) {
			// Any status code that lie within the range of 2xx cause this function to trigger
			// Do something with response data
			return response;
		},
		function (error) {
			// Any status codes that falls outside the range of 2xx cause this function to trigger
			// Do something with response error
			if (
				error.response &&
				(error.response.status == 403 || error.response.status == 401)
			) {
				console.log("AUTH ERROR!", error, error.request, error.response);
				dispatch({ type: "LOGOUT" });
			}

			console.log("error", error);
			return Promise.reject(error);
		}
	);

	const GETRAW = (endpoint, type) => {
		// let key = endpoint.toUpperCase() + '_GET';
		const finalType = type.toUpperCase();

		dispatch({ type: finalType + "_LOADING" });

		return axiosClient({
			method: "get",
			headers: { Authorization: token },
			url: config.apiUrl + "/" + endpoint,
		})
			.then((response) => {
				dispatch({ type: finalType + "_GET", payload: response });
				return response;
			})
			.catch((error) => {
				dispatch({ type: finalType + "_ERROR" });
				console.log("error", error);
				return Promise.reject(error);
			});
	};

	const POSTRAW = (endpoint, type, data) => {
		const finalType = type.toUpperCase();

		dispatch({ type: finalType + "_LOADING" });

		return axiosClient({
			method: "post",
			headers: { Authorization: token },
			url: config.apiUrl + "/" + endpoint,
			data: {
				data: data,
			},
		})
			.then((response) => {
				dispatch({ type: finalType + "_POST", payload: response });
				return response;
			})
			.catch((error) => {
				dispatch({ type: finalType + "_ERROR" });
				console.log("error", error);
				return Promise.reject(error);
			});
	};

	return {
		axiosClient: axiosClient,

		GETRAW: GETRAW,
		POSTRAW: POSTRAW,

		GETIFNEEDED: (endpoint, type) => {
			if (!state[type].loading) {
				return GETRAW(endpoint, type);
			} else {
				console.log("CACHE HIT");
			}
		},

		GET_ACK: () => {
			dispatch({ type: "ACK" });
			return GETRAW(`ack`, "ack");
		},

		GET_EVENT: (id) => {
			dispatch({ type: "EVENT_SET_LOADING", id: id });
			return GETRAW(`events/${id}`, "event");
		},

		// GET_PRODUCT: (id) => {
		// 	dispatch({ type: 'PRODUCT_SET_LOADING', id: id });
		// 	return GETRAW(`products/${id}`, 'product');
		// },

		GET_APPLAUD: (id) => {
			// dispatch({ type: 'PAGE_SET_LOADING', id: id });
			return GETRAW(`applauds/${id}`, "applaud");
		},

		GET_PAGE: (id) => {
			dispatch({ type: "PAGE_SET_LOADING", id: id });
			return GETRAW(`pages/${id}`, "page");
		},

		GET_CATEGORY: (id) => {
			dispatch({ type: "CATEGORY_SET_LOADING", id: id });
			return GETRAW(`categories/${id}`, "category");
		},

		GET_USER: (id) => {
			dispatch({ type: "USER_SET_LOADING", id: id });
			return GETRAW(`users/${id}`, "user");
		},

		GET_GROUP: (id) => {
			dispatch({ type: "GROUP_SET_LOADING", id: id });
			return GETRAW(`groups/${id}`, "group");
		},

		GET_POD: (id) => {
			dispatch({ type: "POD_SET_LOADING", id: id });
			return GETRAW(`pods/${id}`, "pod");
		},

		GET_FEEDITEM: (id) => {
			dispatch({ type: "FEEDITEM_SET_LOADING", id: id });
			return GETRAW(`feed/${id}`, "feeditem");
		},

		GET_FEEDBACKITEM: (id) => {
			dispatch({ type: "FEEDBACKITEM_SET_LOADING", id: id });
			return GETRAW(`feedback/${id}`, "feedbackitem");
		},
		
		GET_FEELS: () => {
			dispatch({ type: "FEELS_LOADING"});
			return GETRAW(`feels/by-week`, "feels");
		},
		
		GET_FEELS_STATUS: () => {
			dispatch({ type: "FEELS_STATUS_LOADING" });
			return GETRAW(`feels/my-status`, "feels_status");
		},			

		INSTAGRAM: (data) => {
			let key = "INSTAGRAM_GET";
			// data.allowithouthashtag = 1;
			let queryStr = data
				? Object.entries(data)
						.map(([key, val]) => `${key}=${val}`)
						.join("&")
				: "";

			return axiosClient({
				method: "get",
				url: config.crowdRiffApiUrl + "?" + queryStr,
			})
				.then((response) => {
					dispatch({ type: key, payload: response });
					return response;
				})
				.catch((error) => {
					Promise.reject(error);
				});
		},

		UPLOAD: (endpoint, data) => {
			const formData = new FormData();
			formData.append("file", data);
			let key = endpoint.toUpperCase();
			dispatch({ type: `${key}_UPLOADING` });

			// return axiosClient.post(config.apiUrl + '/' + endpoint, formData, {
			// 	headers: {
			// 		'Content-Type': 'multipart/form-data',
			// 		Authorization: token,
			// 	},
			// });

			return axiosClient({
				method: "post",
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: token,
				},
				data: formData,
				url: config.apiUrl + "/" + endpoint,
			})
				.then((response) => {
					dispatch({ type: `${key}_UPLOADED`, payload: response });
					return response;
				})
				.catch((error) => {
					dispatch({ type: `${key}_FAILED` });
					console.log("error", error);
					return Promise.reject(error);
				});
		},
		GET: (endpoint) => {
			let key = endpoint.toUpperCase() + "_GET";

			// dispatch({ type: endpoint.toUpperCase() + "_LOADING" });

			return axiosClient({
				method: "get",
				headers: { Authorization: token },
				url: config.apiUrl + "/" + endpoint,
			})
				.then((response) => {
					dispatch({ type: key, payload: response });
					return response;
				})
				.catch((error) => {
					console.log("error", error);
					return Promise.reject(error);
				});
		},
		ADD: (endpoint, data, func = null) => {
			let key = endpoint.toUpperCase() + "_ADD";
			return axiosClient({
				method: "post",
				headers: { Authorization: token },
				url: config.apiUrl + "/" + endpoint,
				data: {
					data: data,
				},
			})
				.then((response) => {
					dispatch({ type: key, payload: response.data });
					if (func) {
						func();
					}
					return response;
				})
				.catch((error) => {
					console.log("error", error);
					return Promise.reject(error);
				});
		},
		EDIT: (endpoint, id, data, func = null) => {
			// let key = endpoint.toUpperCase() + '_EDIT';
			let key = endpoint.toUpperCase();
			dispatch({ type: `${key}_EDITING` });
			//
			return axiosClient({
				method: "post",
				headers: { Authorization: token },

				url: config.apiUrl + "/" + endpoint + "/" + id,
				data: {
					data: data,
				},
			})
				.then((response) => {
					dispatch({ type: key + "_EDITED", payload: response.data });
					if (func) {
						func();
					}
					return response;
				})
				.catch((error) => {
					dispatch({ type: `${key}_ERROR` });
					console.log("error", error);
					return Promise.reject(error);
				});
		},
		DELETE: (endpoint, id) => {
			let key = endpoint.toUpperCase() + "_DELETE";

			return axios
				.delete(config.apiUrl + "/" + endpoint + "/" + id, {
					headers: {
						Authorization: token,
					},
				})
				.then((response) => {
					dispatch({ type: key, payload: response.data.id });
					return response;
				})
				.catch((error) => {
					console.log("error", error);
					return Promise.reject(error);
				});
		},
		EXCHANGE_TOKEN: (endpoint, data, func = null) => {
			let key = endpoint.toUpperCase() + "_GET";
			return axiosClient({
				method: "get",
				url: config.apiUrl + "/" + endpoint,
				params: {
					...data,
				},
			})
				.then((response) => {
					dispatch({ type: key, payload: response });
					if (func) {
						func();
					}
					return response;
				})
				.catch((error) => {
					console.log("error", error);
					return Promise.reject(error);
				});
		},
	};
};
