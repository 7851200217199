import React from "react";

export default function Ticker({ items }) {

	const finalItems =
		items && items.length > 0
			? items
			: Array(4)
				.fill(` Welcome to the <em>Royal Backstage!</em>`);
	// finalItems.push(' Now with a <em>working</em> search functionality! 🙈 ');
				// finalItems.push('Now with 100% more podlings');
				finalItems.push(
					"Now with 100% more <a href='/feels'><i>feeelings</i></a>"
				);
			// : Array(5).fill(` This is a ticker, isn't it <em>great?</em>`);

	return (
		<div className="c-marquee">
			<ul className="marquee__content">
				{finalItems.map((a, i) => (
					<li key={i} dangerouslySetInnerHTML={{__html:a}} />
				))}
			</ul>
			<ul className="marquee__content">
				{finalItems.map((a, i) => (
					<li key={i} dangerouslySetInnerHTML={{__html:a}} />
				))}
			</ul>
		</div>
	);
}