import React, { useState } from 'react';
import { useStore } from '../../store/useStore';
// import Navigation from '../Components/common/Navigation';
// import BackButton from '../Components/common/BackButton';

import { NavLink } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import Timestamp from '../Components/common/Timestamp';
import RequestPermissionToggle from '../Components/common/RequestPermissionToggle';
import { Contact } from './Contacts';
import MainTitle from '../Components/common/MainTitle';
import { apiClient } from '../Helpers/apiCall';
import { HashLink } from 'react-router-hash-link';
import { NPSTrigger } from '../Components/NPS';
import { isAdmin } from '../Helpers/common';

export default function Profile({ reEnableOnboarding }) {
	const { state, dispatch } = useStore();
	const apiCall = apiClient(state, dispatch);
	const { user } = state;

	const [showNotificationDebug, setShowNotificationDebug] = useState(false);

	const [isEditor, setIsEditor] = useState(false);

	const logoutAction = () => {
		dispatch({ type: 'LOGOUT' });
	};

	const saveProfile = (data) => {		
		if (data) {
			apiCall
				.EDIT('users', user.data.id, data)
				.then((response) => {
					if (response.status === 200) {
						dispatch({ type: 'USER_REFRESH', payload: response });
					}
				});
		}
	};

	if (window) window._debugSaveProfile = saveProfile;

	return (
		<div>
			<MainTitle onClick={() => { setIsEditor(!isEditor) }}>Your Profile</MainTitle>
			<div className='c-page'>
				<div className='c-profile'>
					<div className='c-person profile '>
						<Contact user={user?.data} isEditor={isEditor} onEdit={saveProfile} />
					</div>

					<div className='tools'>
						<RequestPermissionToggle loaderClass='light' useIcon={true} />

						<div className='notifications-debugger'>
							<button
								onClick={() => setShowNotificationDebug(!showNotificationDebug)}
							>
								<span className='icon'></span>
								Trouble with notifications?
							</button>
							{showNotificationDebug && (
								<div className='notification-debug'>
									<p>
										Make sure you have updated your iOS/Android and browser to
										the latest version. Notifications also work better with
										saved apps.
									</p>
									<p>
										<strong>Notifications supported?</strong>
										<br />
										<span>
											{typeof Notification !== 'undefined' &&
											'Notification' in window
												? 'Yes'
												: 'No'}
										</span>
									</p>
									<p>
										<strong>Notifications in use?</strong>
										<br />
										<span>
											{typeof Notification !== 'undefined' &&
												(Notification?.permission ?? '-')}
										</span>
									</p>

									{/* <p>
										<label>
											<strong>Saved notification data:</strong>
										</label>
										<span
											style={{
												margin: 0,
												wordBreak: 'break-word',
												fontSize: '0.8em',
											}}
										>
											{user?.data?.notification_settings ?? '-'}
										</span>
									</p> */}
								</div>
							)}
						</div>

						<div className='applauds'>
							<HashLink className='applauds' to={`/draamattupiiri/#${user?.data?.email}`} rel='noopener'>
								<span className='icon'></span>
								Check your compliments &raquo;
							</HashLink>
						</div>
						{/* {user?.data && isAdmin(user.data) && ( */}
							<div className='nps'>
								<NPSTrigger>
									<button>
										<span className='icon'></span>
										Tell us how you're feeling &raquo;
									</button>
								</NPSTrigger>
							</div>
						{/* )} */}
						<div className='feedback'>
							<NavLink activeClassName='active' to={'/feedback'}>
								<span className='icon'></span>
								Leave feedback &raquo;
							</NavLink>
						</div>
						<div className='onboarding'>
							<button onClick={reEnableOnboarding}>
								<span className='icon'></span>
								See tips & instructions &raquo;
							</button>
						</div>
					</div>
					<div className='last-login'>
						<p>
							Last login: <Timestamp timestamp={user?.data?.last_login} />
						</p>
					</div>
					<div className='buttons'>
						<button className='signout-button' onClick={logoutAction}>
							<span className='icon'>
								<svg
									width='19'
									height='18'
									viewBox='0 0 19 18'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M8.153 1.613l.892-.867a.98.98 0 011.362 0l7.81 7.59a.916.916 0 010 1.324l-7.81 7.594a.98.98 0 01-1.362 0l-.892-.867a.92.92 0 01.016-1.34l4.841-4.485H1.464A.949.949 0 01.5 9.626v-1.25c0-.52.43-.938.964-.938H13.01L8.17 2.954a.914.914 0 01-.017-1.34z'
										fill='#FFF'
										fillRule='nonzero'
									/>
								</svg>
							</span>
							Log out
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
